.chat-page {
    display: flex;
    flex-direction: column;
    height: 80vh;
    margin-inline: 80px;
}

.chat-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
}

.chat-user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.chat-body {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #f9f9f9;
}

.sent-0 {
    justify-content: right;
    width: 100%;
    display: flex;
}

.received-0 {
    justify-content: left;
    width: 100%;
    display: flex;
}

.chat-message {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    max-width: 60%;
    position: relative;
}

.sent {
    align-self: flex-end;
    background-color: #f24570;
    color: white;
    text-align: right;
}

.received {
    align-self: flex-start;
    background-color: #fff;
    border: 1px solid #ddd;
}

.chat-footer {
    display: flex;
    padding: 10px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
}

.chat-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.send-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
}

.send-button:hover {
    background-color: #0056b3;
}

.image-select {
    font-size: x-large;
    margin-inline: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom-m-30 {
    margin-bottom: 30px;
}

.chat-footer {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
}

.chat-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
}

.image-select {
    font-size: 24px;
    margin-right: 10px;
    cursor: pointer;
}

.image-preview {
    margin-right: 10px;
    position: relative;
    margin-bottom: 20px;
}

.image-preview img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 5px;
}

.remove-image {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 12px;
}

.chat-image {
    max-width: 150px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.chat-text {
    margin-bottom: 0px;
}

.report-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.report-details {
    width: 100%;
    height: 100px;
    margin: 10px 0;
}

.cancel-button {
    background-color: #f44336;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    margin-inline: 10px;
}

.more-button {
    background-color: #cf0a0a;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}


@media only screen and (max-width: 768px) {
    .chat-page {
        margin-inline: 5px;
    }
}

.delete-button {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 16px;
    margin-right: 8px;
    position: absolute;
    left: -25px; /* Adjust as needed to position it correctly */
    top: 50%;
    transform: translateY(-50%);
}