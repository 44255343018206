.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Slightly darker background for better focus */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears on top */
}

.dialog {
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    width: 500px; /* Wider dialog */
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 90%; /* Responsive on smaller screens */
    text-align: left; /* Align text to the left for readability */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
}

.dialog h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.dialog p {
    font-size: 16px;
    color: #555;
    margin-bottom: 15px;
    line-height: 1.4;
}

.sender-name-input {
    width: 100%;
    padding: 12px;
    margin: 15px 0;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
}

.default-btn-0 {
    background-color: #4a90e2;
    color: #fff;
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease;
    width: 100%;
    margin-top: 10px;
}

.default-btn-0:hover {
    background-color: #357ABD;
}

.close-btn {
    background: none;
    border: none;
    color: #888;
    margin-top: 15px;
    cursor: pointer;
    font-size: 14px;
    display: block;
    text-align: center;
}

.close-btn:hover {
    color: #555;
}
