.main-view {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-card {
  max-width: 600px; /* Increased maximum width for the card */
  width: calc(100% - 40px);
  margin: 20px; /* Added some top and bottom margin */
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Increased shadow for more depth */
  padding: 30px; /* Added padding for a more spacious feel */
  position: relative;
  overflow: hidden; /* Ensure content does not overflow */
}

.profile-card__header {
  text-align: center;
  margin-bottom: 20px;
}

.content__avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 15px;
  object-fit: cover; /* Ensure the image fits within the circle */
}

.age-badge {
  display: inline-block;
  background-color: #ff69b4;
  color: #fff;
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 14px;
  margin-top: 8px;
}

.premium-btn {
  background-color: #ff69b4;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.premium-btn:hover {
  background-color: #ff85c1;
}

.profile-card__detail {
  margin-bottom: 20px;
  font-size: 16px;
}

.profile-card__detail h3 {
  font-size: 18px;
  margin-bottom: 8px;
  color: #333;
}

.interests {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.interest-item {
  background-color: #eee;
  border-radius: 12px;
  padding: 8px 14px;
  font-size: 14px;
}

.message-btn {
  background-color: #ff69b4;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  bottom: 25px;
  right: 25px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.message-btn:hover {
  transform: scale(1.1);
}

.message-btn i {
  font-size: 22px;
}
