.title_hometwo {
    font-size: 27px;
}

.chats-lay {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chats-content {
    width: 800px;
}

.chat-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.user-thumb-0 {
    border-radius: 60px;
    aspect-ratio: 1/1;
    width: 50px;
    min-width: 50px;
    margin-right: 15px;
}

.not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
    font-weight: 600;
    min-height: 300px;
}

.id-date {
    width: 80px;
    margin-inline: 10px;
    height: max-content;
    display: flex;
    align-items: center;
}

.id-date-text {
    font-size: 12px;
    height: max-content;
}

.id-right {
    max-width: 70%;
}

.id-name {
    font-size: 14px;
}

.spacer-0 {
    display: flex;
    flex-grow: 1;
}