#logo-text {
    font-size: 28px; /* Adjust font size as needed */
    font-weight: bold;
    font-family: Arial, sans-serif;
}

/* Style for the first part */
.highlight {
    color: #FF3E72; /* Use the color similar to the first letter */
}

/* Style for the rest of the text */
.normal {
    color: #1F3E72; /* Use the color similar to the rest of the letters */
}