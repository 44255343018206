.profile-picture-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.profile-picture-preview {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
}

.profile-picture-placeholder {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #999;
    margin-bottom: 1rem;
}
