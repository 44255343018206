.member {
    display: flex;
    align-items: center;
    justify-content: center;
}

.users {
    max-width: 60%;
}

.title_hometwo {
    padding-left: 250px;
    padding-block: 25px;
    font-size: xx-large;
    color: #171515;
    font-weight: bold;
}

.location_icon {
    padding-right: 6px;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.thumb {
    aspect-ratio: 3/4;
    object-fit: cover;
}

@media only screen and (max-width: 768px) {
    .title_hometwo {
        padding-left: 30px;
    }

    .users {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 100%;
    }
}