.create-post {
    min-width: 800px;
}

.author-details {
    display: "flex";
    flexGrow: 1;
    flexDirection: "row";
}

@media only screen and (max-width: 768px) {
    .create-post {
        min-width: 300px;
    }

    .post-h-1 {
        display: none;
    }

    .post-h-2 {
        display: none;
    }

    .author-details {
        display: "flex";
        flexDirection: "column";
    }

    .city-tag {
        background-color: brown;
    }
}